import React, { useRef, useState } from 'react';
import { UploadModel } from '../store/UploadModel';

type Props = {
  upload: UploadModel;
  onClose: () => void;
};

const showThreeSecondsChoice = false;

const ChangeExpirationModal = ({ upload, onClose }: Props) => {
  const [invalidDate, setInvalidDate] = useState(false);
  const [customExpiration, setCustomExpiration] = useState(false);
  const yearInputRef = useRef<HTMLInputElement>(null);
  const monthInputRef = useRef<HTMLInputElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const hoursInputRef = useRef<HTMLInputElement>(null);
  const minutesInputRef = useRef<HTMLInputElement>(null);

  const truncate = () => {
    if (upload.filename.length > 40) {
      return `${upload.filename.substring(0, 37)}...`;
    } else {
      return upload.filename;
    }
  };

  const doubleDigit = (input?: string | null) => {
    if (input && input.length === 1) {
      return `0${input}`;
    } else {
      return input;
    }
  };

  const getNewExpiry = () => {
    const expiry = new Date(upload.expiry);
    const year = yearInputRef.current && yearInputRef.current.value;
    const month = doubleDigit(monthInputRef.current && monthInputRef.current.value);
    const date = doubleDigit(dateInputRef.current && dateInputRef.current.value);
    const hours = doubleDigit(hoursInputRef.current && hoursInputRef.current.value);
    const minutes = doubleDigit(minutesInputRef.current && minutesInputRef.current.value);
    const newExpiry = new Date(`${year}-${month}-${date}T${hours}:${minutes}:00.000Z`);
    newExpiry.setMinutes(newExpiry.getMinutes() + expiry.getTimezoneOffset());
    return newExpiry;
  };

  const validate = () => {
    try {
      getNewExpiry().toISOString();
      setInvalidDate(false);
    } catch (error) {
      setInvalidDate(true);
    }
  };

  const onConfirm = () => {
    const newExpiry = getNewExpiry();
    upload.changeExpiration(newExpiry.toISOString());
  };

  const onConfirmSelectionSeconds = (additionalSeconds: number) => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + additionalSeconds);
    upload.changeExpiration(date.toISOString());
  };

  const onConfirmSelection = (additionalDays: number) => {
    const date = new Date();
    date.setDate(date.getDate() + additionalDays);
    upload.changeExpiration(date.toISOString());
  };

  const onConfirmSelectionMonth = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    upload.changeExpiration(date.toISOString());
  };

  const onConfirmSelectionYear = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    upload.changeExpiration(date.toISOString());
  };

  const renderSelect = () => (
    <div className="tukurjub-modal-wrapper ui dimmer modals page top aligned transition visible active">
      <div className="tukurjub-modal ui tiny modal transition visible active">
        <div className="header" style={{ wordWrap: 'break-word' }}>
          Change expiration for "{truncate()}"
        </div>
        <div className="content">
          <div className="buttons-container">
            {showThreeSecondsChoice && (
              <div className="ui button fluid large" onClick={() => onConfirmSelectionSeconds(3)}>
                3 seconds
              </div>
            )}
            <div className="ui button fluid large" onClick={() => onConfirmSelection(1)}>
              1 day
            </div>
            <div className="ui button fluid large" onClick={() => onConfirmSelection(3)}>
              3 days
            </div>
            <div className="ui button fluid large" onClick={() => onConfirmSelection(7)}>
              7 days
            </div>
            <div className="ui button fluid large" onClick={() => onConfirmSelectionMonth()}>
              1 month
            </div>
            <div className="ui button fluid large" onClick={() => onConfirmSelectionYear()}>
              1 year
            </div>
            <div className="ui button fluid large" onClick={() => setCustomExpiration(true)}>
              Custom
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="ui button" onClick={() => onClose()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );

  const renderCustomize = () => {
    const expiry = new Date(upload.expiry);
    return (
      <div className="tukurjub-modal-wrapper ui dimmer modals page top aligned transition visible active">
        <div className="tukurjub-modal ui tiny modal transition visible active">
          <div className="header">Change expiration for "{truncate()}"</div>
          <div className="content">
            <form className="ui form">
              <div className="five fields">
                <div className="field">
                  <label>Year</label>
                  <input
                    ref={yearInputRef}
                    type="text"
                    defaultValue={String(expiry.getFullYear())}
                    onChange={validate}
                  />
                </div>
                <div className="field">
                  <label>Month</label>
                  <input
                    ref={monthInputRef}
                    type="text"
                    defaultValue={String(expiry.getMonth() + 1)}
                    onChange={validate}
                  />
                </div>
                <div className="field">
                  <label>Day</label>
                  <input ref={dateInputRef} type="text" defaultValue={String(expiry.getDate())} onChange={validate} />
                </div>
                <div className="field">
                  <label>Hour</label>
                  <input ref={hoursInputRef} type="text" defaultValue={String(expiry.getHours())} onChange={validate} />
                </div>
                <div className="field">
                  <label>Minutes</label>
                  <input
                    ref={minutesInputRef}
                    type="text"
                    defaultValue={String(expiry.getMinutes())}
                    onChange={validate}
                  />
                </div>
              </div>
              {invalidDate && (
                <div className="ui error message">
                  <p>The entered expiry date is invalid.</p>
                </div>
              )}
            </form>
          </div>
          <div className="actions">
            <div className="ui primary button" onClick={onConfirm}>
              Confirm
            </div>
            <div className="ui button" onClick={() => setCustomExpiration(false)}>
              Back
            </div>
            <div className="ui button" onClick={() => onClose()}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    );
  };

  return customExpiration ? renderCustomize() : renderSelect();
};

export default ChangeExpirationModal;
