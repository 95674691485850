import React, { useRef } from 'react';
import { UploadModel } from '../store/UploadModel';

type Props = {
  upload: UploadModel;
  onClose: () => void;
};

const ChangeNameModal = ({ upload, onClose }: Props) => {
  const nameRef = useRef<HTMLInputElement>(null);

  return (
    <div className="ui dimmer modals page top aligned transition visible active change-expiration-modal-wrapper">
      <div className="change-expiration-modal ui tiny test modal transition visible active">
        <div className="header" style={{ wordWrap: 'break-word' }}>
          Change display name for "{upload.filename}"
        </div>
        <div className="content">
          <form method="post" className="ui form" onSubmit={() => upload.changeName(nameRef.current?.value || '')}>
            <input type="hidden" name="csrfmiddlewaretoken" value="zWpTZuTAEzcSepk5MTKLkacsgIeC1hXP" />
            <div className="field">
              <input
                defaultValue={upload.name || ''}
                autoFocus
                ref={nameRef}
                type="text"
                placeholder="Display name"
                name="display-name"
              />
            </div>
            {/*{error && (*/}
            {/*  <div className="ui error message">*/}
            {/*    <p>{error}</p>*/}
            {/*  </div>*/}
            {/*)}*/}
            <input
              onClick={event => {
                event.preventDefault();
                upload.changeName(nameRef.current?.value || '');
              }}
              type="submit"
              className="ui button"
              value="Change display name"
            />
            <a onClick={() => upload.changeName('')}>Clear display name</a>
          </form>
        </div>
        <div className="actions">
          <div className="ui button" onClick={() => onClose()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeNameModal;
