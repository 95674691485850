/* global XMLHttpRequest */
import { UploadsStore } from '../store/UploadsStore';
import { UploadModel } from '../store/UploadModel';

const getFinishedUploads = (store: UploadsStore): Promise<UploadModel[]> => {
  const url = '/api/files';
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 401) {
          window.location.href = '/login';
          return;
        }
        resolve(
          JSON.parse(xhr.response)
            .reverse()
            .map(
              ({
                expiry,
                file: filename,
                name,
                url,
                id,
              }: {
                expiry: string;
                file: string;
                name?: string;
                url: string;
                id: string;
              }) =>
                new UploadModel({
                  id,
                  url,
                  expiry,
                  filename,
                  name,
                  status: 'Finished',
                  store,
                })
            )
        );
      } else {
        reject(Error(xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(Error('Network error'));
    };
    xhr.open('get', url, true);
    xhr.setRequestHeader('accept', '*/*');
    xhr.send();
  });
};

export default getFinishedUploads;
