/* global XMLHttpRequest, FormData */
import getCookie from '../utils/getCookie';
import { UploadModel } from '../store/UploadModel';

const uploadFile = (file: File | undefined, upload: UploadModel) => {
  if (!file) {
    return;
  }
  const url = '/api/files';
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    upload.setAbortFunc(xhr.abort.bind(xhr));
    let nextTenthOfSecond = 0;
    xhr.upload.onprogress = event => {
      if (new Date().getMilliseconds() / 10 < nextTenthOfSecond) {
        return;
      }
      nextTenthOfSecond = new Date().getSeconds() / 10 + 10;
      upload.setProgress(Math.floor((event.loaded / event.total) * 1000) / 10);
    };
    xhr.onload = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 401) {
          window.location.href = '/login';
          return;
        }
        upload.setProgress(100);
        upload.finishing();
        if (xhr.status === 201) {
          setTimeout(() => resolve(JSON.parse(xhr.response)), 1000);
        } else {
          reject(Error('Something went wrong uploading'));
        }
      } else {
        reject(Error(xhr.statusText));
      }
    };
    xhr.onabort = () => {
      upload.uploadAborted();
    };
    xhr.onerror = () => {
      reject(Error('Network error'));
    };
    xhr.open('post', url, true);
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('X-CSRF-Token', getCookie('x-csrf-token'));
    const formData = new FormData();
    formData.append('file', file);
    xhr.send(formData);
  })
    .then(({ id, expiry, file: filename, url }: any) => upload.finishUpload({ id, url, filename, expiry }))
    .catch((error: Error) => upload.uploadFailed(error));
};

export default uploadFile;
