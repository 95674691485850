import React, { useRef, useState } from 'react';
import getCookie from '../utils/getCookie';

type Props = {
  onClose: any;
};

const ChangePasswordModal = ({ onClose }: Props) => {
  const [error, setError] = useState<string>();
  const oldPasswordInputRef = useRef<HTMLInputElement>(null);
  const newPasswordInputRef = useRef<HTMLInputElement>(null);
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);

  const onChangePassword = () => {
    fetch('/api/user', {
      method: 'PUT',
      credentials: 'same-origin',
      body: JSON.stringify({
        oldPassword: oldPasswordInputRef.current?.value,
        newPassword: newPasswordInputRef.current?.value,
        confirmPassword: confirmPasswordInputRef.current?.value,
      }),
      headers: {
        'X-CSRF-Token': getCookie('x-csrf-token'),
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          onClose();
        } else {
          throw response;
        }
      })
      .catch(error => {
        error.json().then(({ message }: any) => setError(message));
      });
  };

  return (
    <div className="tukurjub-modal-wrapper ui dimmer modals page top aligned transition visible active">
      <div className="tukurjub-modal ui tiny modal transition visible active">
        <div className="header">Change password</div>
        <div className="content">
          <form className="ui form">
            <div className="three fields">
              <div className="field">
                <label>Old password</label>
                <input ref={oldPasswordInputRef} type="text" />
              </div>
              <div className="field">
                <label>New password</label>
                <input ref={newPasswordInputRef} type="text" />
              </div>
              <div className="field">
                <label>Repeat password</label>
                <input ref={confirmPasswordInputRef} type="text" />
              </div>
            </div>
            {error && (
              <div className="ui error message">
                <p>{error}</p>
              </div>
            )}
          </form>
        </div>
        <div className="actions">
          <div className="ui primary button" onClick={onChangePassword}>
            Save
          </div>
          <div className="ui button" onClick={() => onClose()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
