import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import FinishedUploads from './components/FinishedUploads';
import Uploads from './components/Uploads';
import DropZone from './components/DropZone';
import { UploadsStore } from './store/UploadsStore';
import { LoginStore } from './store/LoginStore';
import Login from './components/Login';
import Registration from './components/Registration';
import ActivationRequired from './components/ActivationRequired';
import ChangeExpirationModal from './components/ChangeExpirationModal';
import ChangePasswordModal from './components/ChangePasswordModal';
import ChangeNameModal from './components/ChangeNameModal';

type Props = {
  uploadStore: UploadsStore;
  loginStore: LoginStore;
};

const App = ({
  uploadStore: {
    uploadFile,
    uploadsInProgress,
    finishedUploads,
    uploadToChangeExpiration,
    closeChangeExpiration,
    uploadToChangeName,
    closeChangeName,
  },
  loginStore: { loginError, signupError, registrationComplete },
}: Props) => {
  const [route, setRoute] = useState(window.location.pathname);
  const [showChangePassword, setShowChangePassword] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setRoute(window.location.pathname);
    };
    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [setRoute]);

  if (route === '/registration') {
    return <Registration error={signupError} />;
  } else if (route === '/login') {
    return <Login error={loginError} />;
  } else if (route === '/activationRequired') {
    return <ActivationRequired registrationComplete={registrationComplete} />;
  }

  return (
    <div>
      <DropZone uploadFile={uploadFile} />
      <Uploads uploads={uploadsInProgress} />
      <FinishedUploads finishedUploads={finishedUploads} />
      <footer className="footer">
        <a className="ui button" onClick={() => setShowChangePassword(true)}>
          Change password
        </a>
        <a className="ui button" href="/logout">
          Logout
        </a>
      </footer>
      {uploadToChangeExpiration && (
        <ChangeExpirationModal upload={uploadToChangeExpiration} onClose={() => closeChangeExpiration()} />
      )}
      {uploadToChangeName && <ChangeNameModal upload={uploadToChangeName} onClose={() => closeChangeName()} />}
      {showChangePassword && <ChangePasswordModal onClose={() => setShowChangePassword(false)} />}
    </div>
  );
};

export default observer(App);
