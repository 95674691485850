import { action, computed, makeObservable, observable } from 'mobx';
import Status from '../constants/Status';
import generateId from '../utils/generateId';
import uploadFileApi from '../api/uploadFile';
import changeFileSettingsApi from '../api/changeFileSettings';
import type { UploadsStore } from './UploadsStore';

export class UploadModel {
  file?: File;
  store: UploadsStore;
  abortFunc?: () => void;
  status: string;
  progress: number;
  filename: string;
  name: string;
  url: string;
  expiry: string;
  error?: Error;
  id: string;

  constructor({
    filename,
    name,
    expiry,
    id,
    status,
    url,
    file,
    store,
  }: {
    filename: string;
    name?: string;
    expiry?: string;
    id?: string;
    status?: string;
    url?: string;
    file?: File;
    store: UploadsStore;
  }) {
    this.status = status || Status.INITIALIZED;
    this.progress = 0;
    this.filename = filename.substring(filename.lastIndexOf('/') + 1);
    this.name = name || '';
    this.id = id || generateId();
    this.expiry = expiry || '';
    this.url = url || window.location.href + this.id;
    this.file = file;
    this.store = store;

    makeObservable(this, {
      status: observable,
      progress: observable,
      filename: observable,
      name: observable,
      url: observable,
      expiry: observable,
      error: observable,
      id: observable,

      start: action.bound,
      restart: action.bound,
      setProgress: action.bound,
      uploadAborted: action.bound,
      abortUpload: action.bound,
      finishing: action.bound,
      removeUpload: action.bound,
      finishUpload: action.bound,
      uploadFailed: action.bound,
      deleteUpload: action.bound,
      setAbortFunc: action.bound,
      showChangeExpiration: action.bound,
      changeExpiration: action.bound,
      setExpired: action.bound,
      isExpired: computed,
    });
  }

  start() {
    this.status = Status.UPLOADING;
    uploadFileApi(this.file, this);
  }

  restart() {
    this.setProgress(0);
    this.start();
  }

  setProgress(progress: number) {
    this.progress = progress;
  }

  uploadAborted() {
    this.status = Status.ABORTED;
  }

  abortUpload() {
    this.status = Status.ABORTING;
    this.abortFunc?.();
  }

  finishing() {
    this.status = Status.FINISHING;
  }

  removeUpload() {
    this.store.removeUpload(this);
  }

  finishUpload({ id, url, filename, expiry }: { id: string; url: string; filename: string; expiry: string }) {
    this.id = id;
    this.status = Status.FINISHED;
    this.url = window.location.href + this.id;
    this.filename = filename.substring(filename.lastIndexOf('/') + 1);
    this.expiry = expiry;
  }

  uploadFailed(error: Error) {
    this.status = Status.ERROR;
    this.error = error;
  }

  deleteUpload() {
    this.store.deleteUpload(this);
  }

  setAbortFunc(abortFunc: () => void) {
    this.abortFunc = abortFunc;
  }

  showChangeExpiration() {
    this.store.changeExpiration(this);
  }

  showChangeName() {
    this.store.changeName(this);
  }

  changeExpiration(expiry: string) {
    changeFileSettingsApi(this.id, { expiry }).then(() => {
      this.expiry = expiry;
      this.store.changeExpiration();
    });
  }

  changeName(name: string) {
    changeFileSettingsApi(this.id, { name }).then(() => {
      this.name = name;
      this.store.changeName();
    });
  }

  setExpired() {
    this.status = Status.EXPIRED;
  }

  get isExpired() {
    return this.status === Status.EXPIRED;
  }
}
