/* global XMLHttpRequest */
import getCookie from '../utils/getCookie';

const signup = (username: string, password: string) => {
  const url = '/api/user';
  return new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 401) {
          window.location.href = '/login';
          return;
        }
        if (xhr.status === 201) {
          resolve();
        } else if (xhr.status === 419 || xhr.status === 422) {
          reject({ status: xhr.status, message: JSON.parse(xhr.response).message });
        } else {
          throw new Error('Something went wrong');
        }
      } else {
        throw new Error('Something went wrong');
      }
    };
    xhr.onerror = () => {
      throw new Error('Network error');
    };
    xhr.open('post', url, true);
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('content-type', 'application/json');
    xhr.setRequestHeader('X-CSRF-Token', getCookie('x-csrf-token'));
    xhr.send(JSON.stringify({ username, password }));
  });
};

export default signup;
