import React, { useEffect, useState } from 'react';
import { UploadModel } from '../store/UploadModel';

type Props = {
  upload: UploadModel;
};

const TimeRemaining = ({ upload }: Props) => {
  const getExpiryString = (expiry: string) => {
    const dateDiff = Number(new Date(`${expiry}`)) - Number(new Date());
    if (dateDiff <= 0) {
      return 'Expired';
    }
    const decimalHours = Math.abs(dateDiff) / 3.6e6;
    const days = Math.floor(decimalHours / 24);
    const hours = Math.floor(decimalHours);
    if (days > 7) {
      return new Date(`${expiry}`).toISOString().slice(0, 10);
    } else if (days > 0) {
      return `${days}d ${hours - days * 24}h`;
    }
    const minutes = Math.floor((decimalHours - hours) * 60);
    if (minutes > 0 && hours > 0) {
      return `${hours}h ${minutes}m`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      const seconds = Math.floor(((decimalHours - hours) * 60 - minutes) * 60);
      if (seconds > 0) {
        return `${seconds}s`;
      } else {
        return 'Expired';
      }
    }
  };

  const [expiryString, setExpiryString] = useState(getExpiryString(upload.expiry));

  useEffect(() => {
    const interval = setInterval(() => {
      const newExpiryString = getExpiryString(upload.expiry);
      if (expiryString !== newExpiryString) {
        setExpiryString(newExpiryString);
        if (newExpiryString === 'Expired') {
          console.log('EXPIRED');
          upload.setExpired();
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return <span>{expiryString}</span>;
};

export default TimeRemaining;
