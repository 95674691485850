import React from 'react';
import copy from 'copy-to-clipboard';

type Props = {
  url: string;
};

const CopyLink = ({ url }: Props) => (
  <button className="copyToClipboard" onClick={() => copy(url)}>
    Copy URL
  </button>
);

export default CopyLink;
