/* eslint-disable no-restricted-globals */
const navigate = (to: string, replace?: boolean) => {
  if (replace) {
    history.replaceState({}, '', to);
  } else {
    history.pushState({}, '', to);
  }
  window.dispatchEvent(new Event('popstate'));
};

export const toLogin = () => navigate('/login');

export const toRegistration = () => navigate('/registration');

export const toFrontPage = () => navigate('/', true);

export const toActivationRequired = () => navigate('/activationRequired');
