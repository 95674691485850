import React from 'react';

type Props = {
  registrationComplete: boolean;
};

const ActivationRequired = ({ registrationComplete }: Props) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    {registrationComplete ? (
      <div className="ui positive message">
        <div className="header">Successfully registered</div>
        <p>Please wait until a system administrator activates your account.</p>
      </div>
    ) : (
      <div className="ui error message">
        <div className="header">Activation required</div>
        <p>Please wait until a system administrator activates your account.</p>
      </div>
    )}
  </div>
);

export default ActivationRequired;
