import { action, computed, makeObservable, observable } from 'mobx';
import getFinishedUploadsApi from '../api/getFinishedUploads';
import deleteUploadApi from '../api/deleteUpload';
import Status from '../constants/Status';
import { UploadModel } from './UploadModel';

export class UploadsStore {
  uploads: UploadModel[] = [];
  showChangeExpiration = observable.box<UploadModel | null>(null);
  showChangeName = observable.box<UploadModel | null>(null);

  constructor() {
    makeObservable(this, {
      uploads: observable,
      showChangeExpiration: observable,
      showChangeName: observable,
      allUploads: computed,
      finishedUploads: computed,
      uploadsInProgress: computed,
      fetchUploadsFromServer: action.bound,
      uploadFile: action.bound,
      removeUpload: action.bound,
      deleteUpload: action.bound,
      changeExpiration: action.bound,
      changeName: action.bound,
      closeChangeExpiration: action.bound,
      closeChangeName: action.bound,
      uploadToChangeExpiration: computed,
      uploadToChangeName: computed,
    });
  }

  get allUploads() {
    return this.uploads;
  }

  get finishedUploads() {
    return this.uploads.filter(upload => upload.status === Status.FINISHED || upload.status === Status.EXPIRED);
  }

  get uploadsInProgress() {
    return this.uploads.filter(upload => upload.status !== Status.FINISHED && upload.status !== Status.EXPIRED);
  }

  fetchUploadsFromServer() {
    getFinishedUploadsApi(this).then(
      action(
        (uploads: UploadModel[]) =>
          (this.uploads = [...this.uploads.filter(upload => upload.status !== Status.FINISHED), ...uploads])
      )
    );
  }

  uploadFile(file: File) {
    const upload = new UploadModel({
      filename: file.name,
      file: file,
      store: this,
    });
    this.uploads.unshift(upload);
    upload.start();
    return upload;
  }

  removeUpload(upload: UploadModel) {
    this.uploads = this.uploads.filter(u => u !== upload);
  }

  deleteUpload(upload: UploadModel) {
    deleteUploadApi(upload.id).then(() => this.removeUpload(upload));
  }

  changeExpiration(upload?: UploadModel) {
    this.showChangeExpiration.set(upload || null);
  }

  changeName(upload?: UploadModel) {
    this.showChangeName.set(upload || null);
  }

  closeChangeExpiration() {
    this.showChangeExpiration.set(null);
  }

  closeChangeName() {
    this.showChangeName.set(null);
  }

  get uploadToChangeExpiration(): UploadModel | null {
    return this.showChangeExpiration.get();
  }

  get uploadToChangeName(): UploadModel | null {
    return this.showChangeName.get();
  }
}

export default new UploadsStore();
