/* global XMLHttpRequest */
import getCookie from '../utils/getCookie';

const deleteUpload = (id: string) => {
  const url = `/api/files/${id}`;
  return new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 401) {
          window.location.href = '/login';
          return;
        }
        resolve();
      } else {
        reject(Error(xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(Error('Network error'));
    };
    xhr.open('delete', url, true);
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('X-CSRF-Token', getCookie('x-csrf-token'));
    xhr.send();
  });
};

export default deleteUpload;
