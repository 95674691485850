import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

type Props = {
  uploadFile: (file: File) => void;
};

const DropZone = ({ uploadFile }: Props) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.stopPropagation();
      event.preventDefault();
      setIsDraggingOver(false);
      const files = event.target.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          uploadFile(files[i]);
        }
      }
    },
    [uploadFile, setIsDraggingOver]
  );

  const handleClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  }, [fileInputRef.current]);

  useEffect(() => {
    const handleDrag = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.type === 'dragenter' || event.type === 'dragover') {
        setIsDraggingOver(true);
      } else if (event.type === 'dragleave') {
        setIsDraggingOver(false);
      }
    };

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(false);
      const files: FileList | Array<any> = event.dataTransfer?.files || [];
      for (let i = 0; i < files.length; i++) {
        uploadFile(files[i]);
      }
    };

    document.body.addEventListener('dragenter', handleDrag);
    document.body.addEventListener('dragover', handleDrag);
    document.body.addEventListener('drop', handleDrop);

    return () => {
      document.body.removeEventListener('dragenter', handleDrag);
      document.body.removeEventListener('dragover', handleDrag);
      document.body.removeEventListener('drop', handleDrop);
    };
  }, [uploadFile]);

  return (
    <div>
      <div onClick={handleClick} className="ui icon message upload-header">
        <i className="cloud upload icon" />
        <div className="content">
          <div className="header">Click here to select files to upload</div>
          <p>Or simply drag them to the browser window</p>
        </div>
      </div>
      <input ref={fileInputRef} onChange={handleSelect} type="file" style={{ display: 'none' }} multiple />
      {isDraggingOver && (
        <div className="dragOver">
          <div className="dragOverContent">+</div>
        </div>
      )}
    </div>
  );
};

export default observer(DropZone);
