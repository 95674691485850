import { observable, action, computed, makeObservable } from 'mobx';
import loginApi from '../api/login';
import signupApi from '../api/signup';
import * as Navigate from '../utils/navigate';
import UploadsStore from './UploadsStore';

export class LoginStore {
  _registrationComplete = observable.box(false);
  _loginError = observable.box('');
  _signupError = observable.box('');
  constructor() {
    makeObservable(this, {
      _registrationComplete: observable,
      _loginError: observable,
      _signupError: observable,
      login: action.bound,
      signup: action.bound,
      setRegistrationComplete: action.bound,
      registrationComplete: computed,
      setSignupError: action.bound,
      signupError: computed,
      setLoginError: action.bound,
      loginError: computed,
    });
  }

  login(username: string, password: string) {
    this.setLoginError('');
    loginApi(username, password)
      .then(
        () => {
          Navigate.toFrontPage();
          UploadsStore.fetchUploadsFromServer();
        },
        () => {
          this.setLoginError('Invalid username and/or password');
        }
      )
      .catch(error => {
        console.error(error);
        this.setLoginError('An unknown error occurred, please try again later');
      });
  }

  signup(username: string, password: string) {
    this.setSignupError('');
    signupApi(username, password)
      .then(
        () => {
          this.setRegistrationComplete(true);
          Navigate.toActivationRequired();
        },
        ({ message }) => {
          this.setSignupError(message);
        }
      )
      .catch(error => {
        console.error(error);
        this.setSignupError('An unknown error occurred, please try again later');
      });
  }

  setRegistrationComplete(registrationComplete: boolean) {
    this._registrationComplete.set(registrationComplete);
  }

  get registrationComplete() {
    return this._registrationComplete.get();
  }

  setSignupError(error: string) {
    this._signupError.set(error);
  }

  get signupError() {
    return this._signupError.get();
  }

  setLoginError(error: string) {
    this._loginError.set(error);
  }

  get loginError() {
    return this._loginError.get();
  }
}

export default new LoginStore();
