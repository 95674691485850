import React, { useRef, useState } from 'react';
import LoginStore from '../store/LoginStore';
import * as Navigate from '../utils/navigate';

type Props = {
  error: string;
};

const Registration = ({ error }: Props) => {
  const [validationError, setValidationError] = useState('');
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const handleSignup = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (validate() && usernameRef.current && passwordRef.current) {
      LoginStore.signup(usernameRef.current.value, passwordRef.current.value);
    }
  };

  const validate = () => {
    if (confirmPasswordRef.current?.value !== passwordRef.current?.value) {
      setValidationError('Passwords must match');
      return false;
    }
    return true;
  };

  return (
    <div className="ui text container">
      <h1 className="ui center aligned icon header">
        <i className="circular cloud icon" />
        Register
      </h1>
      <form method="post" className="ui form">
        <input type="hidden" name="csrfmiddlewaretoken" value="zWpTZuTAEzcSepk5MTKLkacsgIeC1hXP" />
        <div className="field">
          <input autoFocus ref={usernameRef} type="text" placeholder="Username" />
        </div>
        <div className="field">
          <input ref={passwordRef} type="password" placeholder="Password" />
        </div>
        <div className="field">
          <input ref={confirmPasswordRef} type="password" placeholder="Confirm password" />
        </div>
        {(validationError || error) && (
          <div className="ui error message">
            <p>{validationError || error}</p>
          </div>
        )}
        <button className="ui button" type="submit" onClick={handleSignup}>
          Register
        </button>
        <a onClick={Navigate.toLogin}>Cancel</a>
      </form>
    </div>
  );
};

export default Registration;
