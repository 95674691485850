import React from 'react';
import CopyLink from './CopyLink';
import TimeRemaining from './TimeRemaining';
import MediaQuery from 'react-responsive';
import { observer } from 'mobx-react';
import { UploadModel } from '../store/UploadModel';

type Props = {
  finishedUpload: UploadModel;
};

const FinishedUpload = ({ finishedUpload }: Props) => (
  <tr>
    <td className="finished-upload__filename" onClick={() => finishedUpload.showChangeName()}>
      <MediaQuery maxWidth={767} component="span">
        File:{' '}
      </MediaQuery>
      {finishedUpload.name || finishedUpload.filename}
    </td>
    <td className="collapsing">
      <MediaQuery maxWidth={767} component="span">
        Expiry:{' '}
      </MediaQuery>
      <TimeRemaining upload={finishedUpload} />
    </td>
    <td className="finished-upload__url">
      <a href={finishedUpload.url}>{finishedUpload.url}</a>
    </td>
    <td className="collapsing">
      {finishedUpload.isExpired ? (
        <a onClick={() => finishedUpload.removeUpload()}>Remove from list</a>
      ) : (
        <span>
          <CopyLink url={finishedUpload.url} />
          <span>
            {' '}
            | <a onClick={() => finishedUpload.showChangeExpiration()}>Change expiration</a> |{' '}
            <a onClick={() => finishedUpload.deleteUpload()}>Delete</a>
          </span>
        </span>
      )}
    </td>
  </tr>
);

export default observer(FinishedUpload);
