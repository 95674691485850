const Status = {
  INITIALIZED: 'Initialized',
  UPLOADING: 'Uploading',
  ERROR: 'Error',
  FINISHING: 'Finishing',
  FINISHED: 'Finished',
  ABORTING: 'Aborting',
  ABORTED: 'Aborted',
  EXPIRED: 'Expired',
};

export default Status;
