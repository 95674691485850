import './master.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UploadStore from './store/UploadsStore';
import LoginStore from './store/LoginStore';
import * as mobx from 'mobx';

mobx.configure({ enforceActions: 'always' });

// FIXME
if (process.env.NODE_ENV !== 'production') {
  (window as any).uploadStore = UploadStore;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App uploadStore={UploadStore} loginStore={LoginStore} />
  </React.StrictMode>
);

// FIXME -- Better check for login
if (!window.location.href.includes('/login')) {
  UploadStore.fetchUploadsFromServer();
}

// FIXME - Better check for login
document.addEventListener('visibilitychange', event => {
  if (document.visibilityState === 'visible') {
    if (!window.location.href.includes('/login')) {
      UploadStore.fetchUploadsFromServer();
    }
  }
});
