import React, { useRef } from 'react';
import LoginStore from '../store/LoginStore';
import * as Navigate from '../utils/navigate';

type Props = {
  error: string;
};

const Login = ({ error }: Props) => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const handleLogin = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    LoginStore.login(String(usernameRef.current?.value), String(passwordRef.current?.value));
  };
  return (
    <div className="ui text container">
      <h1 className="ui center aligned icon header">
        <i className="circular cloud icon" />
        Insert coin to start
      </h1>
      <form method="post" className="ui form" onSubmit={handleLogin}>
        <input type="hidden" name="csrfmiddlewaretoken" value="zWpTZuTAEzcSepk5MTKLkacsgIeC1hXP" />
        <div className="field">
          <input autoFocus ref={usernameRef} type="text" placeholder="Username" name="username" />{' '}
        </div>
        <div className="field">
          <input ref={passwordRef} type="password" placeholder="Password" name="password" />{' '}
        </div>
        {error && (
          <div className="ui error message">
            <p>{error}</p>
          </div>
        )}
        <input type="submit" className="ui button" value="Login" />
        <a onClick={Navigate.toRegistration}>Sign up</a>
      </form>
    </div>
  );
};

export default Login;
